<template>

	<div>

		<headapp></headapp>
		<div style="background-color: white" class="p-1 px-2 breadcrumbWrap">
			<div style="background-color: rgba(0,0,0,.06); border-radius: 3px; width: auto" class="px-2 py-2">
				<b-row align-v="center">
					<b-col cols="9">
						<b-breadcrumb style="background-color: transparent" class="m-0 p-0" :items="breadcrumb"></b-breadcrumb>
					</b-col>
					<b-col class="text-right" v-if="$route.meta.contesto == 'giocatori'">
						<b-button variant="secondary" size="xs" @click="showAddPartita"><em class="fa fa-plus"></em> Aggiungi Partita</b-button>
					</b-col>
				</b-row>
			</div>

		</div>
		<div class="app-space" :style="'height: '+ux.height+'px'" ref="layout">
			<div style="background-color: white; height: 100%" v-if="competizione" class="px-2"><!-- 🧙‍♀️ -->
				<b-overlay :show="ready" ref="waitLayer">
					<b-row>
						<b-col :cols="3">

							<div class="prePartite">
								<b-form-select v-if="stagioni && stagioni.length > 0" size="sm" v-model="seasonId" class="mb-1">
									<b-form-select-option v-for="s in stagioni" :value="s.wyscout.chiave">{{ competizione.nome }} - {{ s.nome }}</b-form-select-option>
									<b-form-select-option :value="-1">Tutte le Partite</b-form-select-option>
								</b-form-select>
							</div>

							<b-overlay :show="wyScoutWait">
								<ListaPartite :height="ux.height" v-if="thePartite.length > 0" :partite="thePartite" :season="seasonId == -1"></ListaPartite>
								
								<b-alert show v-if="thePartite.length == 0 && !wyScoutWait">
									Utilizza il menù a tendina per raffinare la ricerca.
								</b-alert>

							</b-overlay>
						</b-col>
						<b-col :cols="9" :style="'overflow-y: scroll; height: '+ux.height+'px'">
							<div v-if="$route.meta.contesto == 'giocatori'">
								<RosaGiocatori v-if="players" :players="players" :team="squadra"></RosaGiocatori>
							</div>
							<div v-else-if="$route.meta.contesto == 'partita-partite'">
								<ContenitorePartitaScoutingManual ref="interfacciaPartita" @ready="isReady" @wait="isWaiting" :partiteId="$route.params.partite_id"></ContenitorePartitaScoutingManual>
							</div>
							<div v-else-if="$route.meta.contesto == 'partita-partite-wyscout'">
								<ContenitorePartitaScoutingWyDatabase ref="interfacciaPartita" @ready="isReady" @wait="isWaiting":partiteId="$route.params.partite_id" :wyId="$route.params.wy_id"></ContenitorePartitaScoutingWyDatabase>
							</div>
							<div v-else-if="$route.meta.contesto == 'partita-wyscout'">
								<ContenitorePartitaScoutingWy ref="interfacciaPartita" @ready="isReady" @wait="isWaiting" :wyId="$route.params.wy_id"></ContenitorePartitaScoutingWy>
							</div>
						</b-col>
					</b-row>
				</b-overlay>
			</div>

			<b-modal id="matchModal" ref="matchModal" title="Aggiungi partita" ok-only ok-title="Chiudi">
				<b-form-group label="Competizione">
					<b-form-input v-if="competizione" type="text" v-model="competizione.nome" plaintext></b-form-input>
				</b-form-group>
				<b-form-group label="Squadra">
					<b-form-input v-if="squadra" type="text" v-model="squadra.officialName" plaintext></b-form-input>
				</b-form-group>
				<b-form-group label="Avversario" :label-cols="3" label-for="squadra">
					<b-input-group>
						<b-input-group-prepend is-text>
							<b-img :src="logo_avversario ? logo_avversario : '/assets/varie/team.png'" width="24"/>
						</b-input-group-prepend>
						<b-form-input type="text" v-model="avversario" readonly></b-form-input>
						<b-input-group-append>
							<b-button variant="primary" @click="loadSquadra" title="Carica squadra da archivio"><i class="fa fa-folder-open" aria-hidden="true"></i></b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<b-form-group id="modalitaGroup" label="Dove" :label-cols="3">
					<b-form-radio-group id="modalita" v-model="form_partita.home">
						<b-form-radio :value="1">Casa</b-form-radio>
						<b-form-radio :value="0">Trasferta</b-form-radio>
					</b-form-radio-group>
				</b-form-group>

				<b-form-group id="dataGroup" label="Data / Ora">
					<b-row>
						<b-col cols="8">
							<b-form-input id="data" type="date" v-model="form_partita.data"></b-form-input>
						</b-col>
						<b-col>
							<b-form-input id="ora" type="time" v-model="form_partita.ora"></b-form-input>
						</b-col>
					</b-row>
				</b-form-group>
				<b-form-group label="Risultato">
					<b-form-input type="text" v-model="form_partita.score"></b-form-input>
				</b-form-group>
				<b-button type="button" @click="salvaPartita" variant="primary" class="mt-3">Salva</b-button>
			</b-modal>
			<b-modal id="loadTeamModal" ref="loadTeamModal" title="Seleziona squadra" ok-only ok-title="Chiudi" size="lg">
				<div>
					<div style="height: 250px; overflow-y: scroll; overflow-x: hidden; padding-left:20px;padding-right:20px;">
						<b-row class="mb-1">
							<b-col sm="6">
								<b-form-select v-model="filters.categoria">
									<option value="0">-- Categoria --</option>
									<option v-for="item in categories_opt" :value="item.id" :key="item.id">{{item.name}}</option>
								</b-form-select>
							</b-col>
							<b-col sm="6">
								<b-input-group>
									<b-form-input type="text" v-model="filters.search_name" placeholder="Squadra"></b-form-input>
									<b-input-group-append>
										<b-button variant="primary" @click="filtraArchivio"><i class="fa fa-search" aria-hidden="true"></i></b-button>
									</b-input-group-append>
								</b-input-group>  	
							</b-col>
						</b-row>
						<div v-if="teams.data && teams.data.length == 0" class="my-2">
							<h5>Nessuna squadra trovata!</h5>
							<b-button :to="{ name: 'newscout_squadre_new'}" variant="outline-primary" target="_blank" class="mt-2">Aggiungi squadra</b-button>
						</div>	
						<b-row>
							<b-col cols="4" v-for="team in teams.data" v-bind:key="team.id" style="padding: 0.2rem">
								<b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="scegliSquadra(team)">
									<img :src="team.logo_url" width="50" /><br/>
									<strong>{{team.officialName}}</strong><br/>
									<span>{{ team.categoria ? team.categoria.name : '&nbsp;' }}</span><br/>
								</b-card>
							</b-col>
						</b-row>	
						<b-row>
							<b-col cols="6" style="padding: 0.2rem">
								<a v-if="teams.current_page - 1 != 0" class="page-link" @click="paginate(parseInt(teams.current_page) - 1)" >Indietro</a>
							</b-col>	

							<b-col cols="6" style="padding: 0.2rem">
								<a v-if="teams.current_page < teams.last_page" class="page-link" @click="paginate(parseInt(teams.current_page) + 1)">Avanti</a>
							</b-col>	
						</b-row>				
					</div>
				</div>
			</b-modal>

		</div>

		<footerapp></footerapp>

	</div>

</template>

<script>

import $ from 'jquery'
import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	components: {
		RosaGiocatori: require('@/components/layout/scout/rosa-giocatori.vue').default,
		ListaPartite: require('@/components/layout/scout/lista-partite.vue').default,
		ContenitorePartitaScoutingManual: require('@/components/layout/scout/contenitore-partita-manual.vue').default,
		ContenitorePartitaScoutingWyDatabase: require('@/components/layout/scout/contenitore-partita-wy-database.vue').default,
		ContenitorePartitaScoutingWy: require('@/components/layout/scout/contenitore-partita-wy.vue').default,
	},
	data: function() {
		return {
			continente: null,
			paese: null,
			wyScoutWait: false,
			competizione: null,
			seasonId: null, // wy scout
			squadra: null,
			players: null,
			partite: null,
			partiteWy: null,
			thePartite: [],
			ready: false,
			stagioni: null,
			ux: {
				height: null
			},

			form_partita: {
				id: null,
				id_competizione: null,
				id_squadra: null,
				id_avversario: null,
				home: 1,
				data: null,
				ora: null,
				score: null,
			},
			logo_avversario: null,
			avversario: null,

			page: 1,
			categories_opt: [],
			teams: {
				data: [],
			},
			filters: {
				paese: 0,
				osservati: 0,
				videotags: 0,
				search_name: '',
				search_coach: '',
				reports: 0,
				missioni: 0,
				categoria: 0,
			},
		}
	},
	mounted: function() {

		this._.delay(function() {
			this.uiDesign();
		}.bind(this), 400);

		$(window).on('resize', function() {
			this.uiDesign();
		}.bind(this));

	},
	computed: {
		breadcrumb: function() {

			var b = [];

			var isNazionale = false;
			
			b.push({
				text: 'Continenti',
				to: {
					name: 'scout_continenti'
				}
			});

			if(this.$route.params.continente_id) {
				b.push({
					text: this.continente ? this.continente.paese : '',
					to: {
						name: 'scout_paesi',
						params: {
							continente_id: this.$route.params.continente_id
						}
					}
				});
			}
			if(this.$route.params.paese_id) {
				b.push({
					text: this.paese ? this.paese.paese : '',
					to: {
						name: 'scout_competizioni',
						params: {
							continente_id: this.$route.params.continente_id,
							paese_id: this.$route.params.paese_id
						}
					}
				});
			}

			/* Nazionali */
			if(this.$route.params.competizione_id == undefined || this.$route.params.competizione_id == 0) {
				isNazionale = true;

				if(this.$route.params.paese_id) {
					b.push({
						text: 'Nazionali',
						to: {
							name: 'scout_nazionali',
							params: {
								continente_id: this.$route.params.continente_id,
								paese_id: this.$route.params.paese_id
							}
						}
					});
				}
			} else {

				b.push({
					text: this.competizione ? this.competizione.nome : '',
					to: {
						name: 'scout_competizione',
						params: {
							continente_id: this.$route.params.continente_id,
							paese_id: this.$route.params.paese_id,
							competizione_id: this.$route.params.competizione_id
						}
					}
				});
			}

			if(this.$route.meta.contesto == 'giocatori') {

				b.push({
					text: this.squadra ? this.squadra.officialName : '',
					current: true
				});

			} else {

				if(this.$route.params.competizione_id && this.$route.params.paese_id && this.$route.params.squadra_id && this.$route.params.continente_id) {
					b.push({
						text: this.squadra ? this.squadra.officialName : '',
						to: {
							name: 'scout_competizione_squadra',
							params: {
								continente_id: this.$route.params.continente_id,
								competizione_id: this.$route.params.competizione_id,
								paese_id: this.$route.params.paese_id,
								squadra_id: this.$route.params.squadra_id
							}
						}
					});
				} else if(this.$route.params.paese_id && this.$route.params.squadra_id && this.$route.params.continente_id) {
					b.push({
						text: this.squadra ? this.squadra.officialName : '',
						to: {
							name: 'scout_nazionali_squadra',
							params: {
								continente_id: this.$route.params.continente_id,
								paese_id: this.$route.params.paese_id,
								squadra_id: this.$route.params.squadra_id
							}
						}
					});
				} else {
					b.push({
						text: this.squadra ? this.squadra.officialName : '',
						to: {
							name: 'scout_squadra',
							params: {
								squadra_id: this.$route.params.squadra_id
							}
						}
					});
				}	

				if(this.ready == false) {
					b.push({
						text: this.$refs.interfacciaPartita ? this.$refs.interfacciaPartita.label : 'Dettaglio'
					});
				} else {
					b.push({
						text: '... Attendi Caricamento'
					});
				}

			}
			return b;
		},
		area: function() {
			return this.$route.meta.area;
		},
		partiteIn: function() {
			return _.filter(this.partiteWy, function(a) { return a.partiteId != null });
		},
		partiteWs: function() {
			return _.filter(this.partiteWy, function(a) { return a.partiteId == null });
		},
	},
	watch: {
		'$route.params': {
			deep: true,
			handler: function() {

				if(!this.$refs.interfacciaPartita) return false; // Tanto vuol dire che è la prima.. non serve resettare ^_^
				this.$refs.interfacciaPartita.reset();

			}
		},
		'competizione': function() { this.mergeMatches(); },
		'partite': function() { this.mergeMatches(); },
		'partiteWs': function() { this.mergeMatches(); },
		'$route.params.squadra_id': {
			immediate: true,
			handler: function() {
				this.fetchContinente();
				this.fetchPaese();
				this.getSquadra();
				this.getPartite();
				this.getCompetizione(this.$route.params.competizione_id);
				this.getStagioni(this.$route.params.competizione_id);
			}
		},
		squadra: {
			handler: function() {
				if(this.squadra && this.squadra.wyscout && !this.seasonId) {
					this.getPlayers(this.squadra.wyscout.chiave);
					this.getWsMatches(this.squadra.wyscout.chiave, null);
				}
			}
		},
		seasonId: {
			handler: function() {
				if(this.squadra && this.squadra.wyscout) {
					this.getPlayers(this.squadra.wyscout.chiave);
					this.getWsMatches(this.squadra.wyscout.chiave, this.seasonId);
				}	
			}
		}
	},
	methods: {
		getLabel: function() {
			console.log(this.$refs.interfacciaPartita.label);
		},
		goBack: function() {

			if(this.$route.params.competizione_id == undefined || this.$route.params.competizione_id == 0) {
				this.$router.push({
					name: 'scout_nazionali',
					params: {
						continente_id: this.$route.params.continente_id,
						paese_id: this.$route.params.paese_id
					}
				});

			} else {
				this.$router.push({
					name: 'scout_competizione',
					params: {
						continente_id: this.$route.params.continente_id,
						paese_id: this.$route.params.paese_id,
						competizione_id: this.$route.params.competizione_id,
					}
				});
			}
		},
		uiDesign: function() {

			var space = $(window).height() - $('header').outerHeight() - $('.breadcrumbWrap').outerHeight() - $('footer').outerHeight();

			this.ux.height = space;

			//console.log($(this.$el).height(), space);

		},
		isReady: function() { this.ready = false; },
		isWaiting: function() { this.ready = true; },
		mergeMatches: function() {

			var partite = [];
			//this.$refs.waitLayer.show = true;
			if(!this.competizione) return false;
			
			//if(this.partite || this.partiteWy)
			//	this.$refs.waitLayer.show = false;

			_.each(this.partiteWy, function(wy) {

				partite.push({
					data: wy.date,
					video: wy.match_football ? wy.match_football.video : ( wy.video_scouting ? wy.video_scouting : null ),
					valutazioni: wy.countVal,
					label: wy.label,
					partitaFootballId: wy.match_football ? (wy.match_football.id) : null,
					partiteId: wy.partiteId ? wy.partiteId : null,
					partitaWyScoutId: wy.matchId? wy.matchId : null,
					competizione: wy.competizione,
					allegati: wy.allegati ? wy.allegati : null ,
				});

			}.bind(this));

			_.each(this.partite, function(match) {
				if(match.id_competizione == this.competizione.id) {
					partite.push({
						data: match.data,
						video: match.match_football ? match.match_football.video : ( match.video_scouting ? match.video_scouting : null ),
						valutazioni: match.countVal,
						label: _.sortBy(match.squadre, ['pivot.side'], ['desc'])[0].name + ' - ' + _.sortBy(match.squadre, ['pivot.side'], ['desc'])[1].name + (match.score != '/' ? ', ' +  match.score : ''),
						partitaFootballId: match.match_football ? (match.match_football.id) : null,
						partiteId: match.id,
						partitaWyScoutId: match.wyscout_id,
						competizione: match.competizione,
						allegati: match.allegati ? match.allegati : null ,
					});
				}
			}.bind(this));

			//console.log(partite);
			this.thePartite = _.orderBy(partite, ['data'], ['desc']);

			//if(this.thePartite.length == 0 && this.wyScoutWait == false)
			//	this.seasonId = -1;

		},
		getStagioni: function(id) {

			this.$http.get(this.$store.state.apiEndPoint + '/competizioni/' + id + '/stagioni').then(function(data) {
				this.stagioni = data.data;
			})

		},
		getCompetizione(id) {
			this.competizione = null;
			this.$http.get(this.$store.state.apiEndPoint + '/club/competizione/' + id).then(function(data) {
				this.competizione = data.data;
			});
		},
		getPartite: function() {
			this.partite = [];
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/partite/inserite/' +  this.$route.params.squadra_id + '/' + this.area).then(function(data) {
				this.partite = data.data;
			});
		},
		getWsMatches: function(id, idSeason) {
			this.wyScoutWait = true;
			this.partiteWy = [];
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/partite/' +  id + '/' + this.area, {
				params: {
					id_stagione: idSeason != -1 ? idSeason : null,
					id_competizione: idSeason != -1 ? this.$route.params.competizione_id : null
				}
			}).then(function(data) {
				this.wyScoutWait = false;
				this.partiteWy = data.data;
				if(this.$route.params.competizione_id == undefined)
					this.seasonId = -1;
				else if(this.partiteWy.length && idSeason != -1) {
					this.seasonId = _.first(this.partiteWy).seasonId;
				}
			});
		},
		fetchContinente: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/paese/' + this.$route.params.continente_id).then(function(data) {
				this.continente = data.data;
			});
		},
		fetchPaese: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/paese/' + this.$route.params.paese_id).then(function(data) {
				this.paese = data.data;
			});
		},
		getSquadra: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/squadre/get/' + this.$route.params.squadra_id).then(function(data) {
				this.squadra = data.data;
			});
		},
		getPlayers: function(id) {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionale/giocatori/' + id + '/' + this.area).then(function(data) {
				this.players = data.data;
			});	
		},
		showAddPartita() {
			this.form_partita.id = 0;
			this.form_partita.id_competizione = this.competizione.id;
			this.form_partita.id_squadra = this.squadra.id;
			this.form_partita.id_avversario = null;
			this.form_partita.home = 1;
			this.form_partita.data = null;
			this.form_partita.ora = null;
			this.form_partita.score = null;
			this.avversario = null;
			this.logo_avversario = null;
			this.$refs.matchModal.show();
		},
		salvaPartita() {
			if(!this.form_partita.id_avversario) {
				alert("Devi selezionare una squadra avversaria!");
				return;
			}
			if(!this.form_partita.data) {
				alert("Devi selezionare la data della partita!");
				return;
			}
			if(!this.form_partita.ora) {
				alert("Devi selezionare l'ora della partita!");
				return;
			}
			if(!this.form_partita.score || (this.form_partita.score && this.form_partita.score == '')) {
				alert("Devi inserire il risultato della partita!");
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/nazionale/salva/partita', this.form_partita).then(function(data) {
				this.alertPush(data.data.message, 1);
				this.getPartite();
				this.$refs.matchModal.hide();
			}).catch(function (error) {
				for (var index in error.data) {
					this.alertPush(error.data[index], 0);
				}
			});
		},
		loadSquadra: function() {
			this.getCategories();
			this.getTeams();
			this.$refs.loadTeamModal.show();	
		},
		getCategories() {
			this.$http.get(this.$store.state.apiEndPoint + `/exercise/categories`)
			.then(function(data) {
				this.categories_opt = data.data.map(item => ({
					name: item.name,
					id: item.id,
				}));
			});
		},
		getTeams: function () {
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre?page='+this.page, {params: this.filters}).then(function(data) {
				this.teams = data.data;	
			});						
		},
		filtraArchivio() {
			this.teams = [];
			this.page = 1;
			this.$http.get(this.$store.state.apiEndPoint + '/scouting/archivio/squadre', {params: this.filters}).then(function(data) {
				this.teams = data.data;	
			});
		},
		paginate(page) {
			this.page = page;
			this.getTeams();
		},
		scegliSquadra: function(squadra) {
			this.form_partita.id_avversario = squadra.id;
			this.avversario = squadra.officialName;
			this.logo_avversario = squadra.logo_url;
			this.$refs.loadTeamModal.hide();	
			this.$refs.matchModal.show();
		},
	}
}

</script>