<template>
	<span>
		<b-button size="sm" block @click.prevent="ux.show = !ux.show" variant="outline-success">Nuova Valutazione</b-button>

		<b-sidebar @hidden="ux.show = false" ref="mod" :title="'Nuova Valutazione ' + player.surname" :visible="ux.show" width="800px" backdrop>
			
			<b-card no-body class="mx-2 mb-3">
				<b-row class="align-items-end">
					<b-col cols="1">
						<img :src="player.avatar" width="60">
					</b-col>
					<b-col class="pb-1 text-left" cols="8">
						<div style="text-transform: uppercase; line-height: 1">{{player.surname}}</div>
						<div class="small text-muted" style="line-height: 1">{{player.name}}<span style="font-weight: bold; text-transform: uppercase">, {{player.role}}</span></div>
					</b-col>
					<b-col v-if="player.team" class="pb-1">
						<b-row class="text-left">
							<b-col cols="2">
								<img :src="player.team.logo_url" width="100%" />
							</b-col>
							<b-col>
								<div style="line-height: 1">{{player.team.name}}</div>
								<div style="line-height: 1" class="small text-muted" v-if="player.team.paese">{{player.team.paese.name}}</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-card>

			<div class="px-2">
				<b-nav tabs>
					<b-nav-item active>Scouting</b-nav-item>
					<b-nav-item disabled>Football</b-nav-item>
					<b-nav-item disabled>Medical</b-nav-item>
				</b-nav>
					
				<div class="px-2" style="background-color: white; border-left: 1px solid rgba(0,0,0,.1); border-right: 1px solid rgba(0,0,0,.1); border-bottom: 1px solid rgba(0,0,0,.1);">

					<div v-if="valutazioneGiocatore.length > 0" class="py-1">
						<div v-for="a,k in valutazioneGiocatore" class="my-3" style="background-color: rgba(0,0,0,.05); border-radius: 5px; padding: 0.5em; border: 1px solid rgba(0,0,0,.1)">
							<b-row class="d-flex align-items-center mb-2">
								<b-col>
									<p class="lead ml-1 mb-0">Valutazione <span style="text-decoration: underline; font-weight: bold">{{generateName(a)}}</span></p>
								</b-col>
								<b-col class="text-right"><a href="javascript:;" @click.prevent="removePlayer(k)">&times; Rimuovi</a></b-col>
							</b-row>
							<div :is="calculateTypePlayer(a)"></div>
						</div>
					</div>

					<hr v-if="valutazioneGiocatore.length > 0" />
					<div v-else class="py-1" style="width: 5px"></div>
					<div class="lead text-muted my-2 text-center" v-if="valutazioneGiocatore.length > 0">Inserisci un altra valutazione</div>

					<b-row class="align-items-center" :class="{ 'my-2': valutazioneGiocatore.length == 0 }">
						<b-col cols="3" class="mb-1" style="height: 100%" v-for="val in typeOfPlayerValutations">
							<b-card align="center" @click.prevent="addValutazioneGiocatore(val.component)" :style="(isPlayerClick(val.component) ? 'cursor:pointer;height:100%;' : 'opacity: .5;') + 'line-height: 1; height: 72px;'">
								{{ val.name }}
							</b-card>
						</b-col>
					</b-row>
				</div>

				<div class="mt-4" v-if="match">
					<b-card class="mt-2">
						<template slot="header">
							<b-row class="flex-items-xs-middle d-flex">
								<b-col>
									Partita Collegata
								</b-col>
								<b-col>
									<div class="small text-muted text-right">Questa valutazione è collegata ad una partita.</div>
								</b-col>
							</b-row>
						</template>
						<b-row>
							<b-col>
								<b-row class="align-items-center">
									<b-col class="text-center">
										<div>{{ match.partita.label }}</div>
										<div class="small text-muted">{{ match.partita.dateutc }}</div>
									</b-col>
								</b-row>
							</b-col>
							<b-col class="text-right">
								<b-button-group>
								    <b-dropdown @click.prevent="addValutazionePartita('Breve')" variant="outline-success" size="sm" right split text="Aggiungi Valutazione Partita">
								      <b-dropdown-item :disabled="!isMatchClick('Breve')" @click.prevent="addValutazionePartita('Breve')">Base</b-dropdown-item>
								      <b-dropdown-item :disabled="!isMatchClick('Estesa')" @click.prevent="addValutazionePartita('Estesa')">Estesa</b-dropdown-item>
								    </b-dropdown>
								</b-button-group>
							</b-col>
						</b-row>
						<div v-for="val in valutazionePartita" class="my-3" style="background-color: rgba(0,0,0,.05); border-radius: 5px; padding: 0.5em; border: 1px solid rgba(0,0,0,.1)">

							<b-row class="mb-2">
								<b-col>
									<p class="lead ml-1">Valutazione <span style="text-decoration: underline; font-weight: bold">{{generateName(val)}}</span></p>
								</b-col>
								<b-col class="text-right small"><a href="javascript:;" @click.prevent="removeMatch(k)">Annulla</a></b-col>
							</b-row>
							<div :is="calculateTypePartita(val)" class="mb-4"></div>

						</div>
					</b-card>
				</div>

			</div>

		</b-sidebar>

	</span>
</template>

<script>

var ValutazioniPlayer = [
	{ name: 'QUALITATIVE REPORT AM', component: 'QualitativeReportAm', role: [ 'df', 'md', 'fw' ] },
	{ name: 'QUALITATIVE REPORT CB', component: 'QualitativeReportCb', role: [ 'df', 'md', 'fw' ] },
	{ name: 'QUALITATIVE REPORT CF', component: 'QualitativeReportCf', role: [ 'df', 'md', 'fw' ] },
	{ name: 'QUALITATIVE REPORT CM', component: 'QualitativeReportCm', role: [ 'df', 'md', 'fw' ] },
	{ name: 'QUALITATIVE REPORT FB', component: 'QualitativeReportFb', role: [ 'df', 'md', 'fw' ] },
	{ name: 'QUALITATIVE REPORT GK', component: 'QualitativeReportGk', role: [ 'gk' ] },
	{ name: 'QUALITATIVE REPORT W', component: 'QualitativeReportW', role: [ 'df', 'md', 'fw' ] },

	{ name: 'QUANTITATIVE REPORT', component: 'QuantitativeReport', role: null },
	{ name: 'Breve', component: 'Breve', role: null },
	{ name: 'Estesa', component: 'Estesa', role: null },
];

export default {
	props: [ 'player', 'match' ],
	components: {
		ValutazionePartitaBreve: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazionePartitaEstesa: require('@/components/layout/Valutazioni/ValutazionePartitaBreve.vue').default,
		ValutazioneGiocatoreBreve: require('@/components/layout/Valutazioni/ValutazioneGiocatoreBreve.vue').default,
		ValutazioneGiocatoreEstesa: require('@/components/layout/Valutazioni/ValutazioneGiocatoreEstesa.vue').default,
		ValutazioneGiocatoreQualitativeReportGk: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportGk.vue').default,
		ValutazioneGiocatoreQualitativeReportAm: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportAm.vue').default,
		ValutazioneGiocatoreQualitativeReportCb: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCb.vue').default,
		ValutazioneGiocatoreQualitativeReportCf: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCf.vue').default,
		ValutazioneGiocatoreQualitativeReportCm: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportCm.vue').default,
		ValutazioneGiocatoreQualitativeReportFb: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportFb.vue').default,
		ValutazioneGiocatoreQualitativeReportW: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQualitativeReportW.vue').default,
		ValutazioneGiocatoreQuantitativeReport: require('@/components/layout/Valutazioni/ValutazioneGiocatoreQuantitativeReport.vue').default,
	},
	methods: {
		generateName: function(name) {
			return _.startCase(name);
		},
		isPlayerClick: function(k) {
			return this.valutazioneGiocatore.indexOf(k) === -1;
		},
		isMatchClick: function(k) {
			return this.valutazionePartita.indexOf(k) === -1;
		},
		removeMatch: function(k) {
			this.valutazionePartita.splice(k, 1);
		},
		removePlayer: function(k) {
			this.valutazioneGiocatore.splice(k, 1);
		},
		calculateTypePlayer: function(what) {
			return 'ValutazioneGiocatore' + what;
		},
		calculateTypePartita: function(what) {
			return 'ValutazionePartita' + what;
		},
		addValutazioneGiocatore: function(tipo) {
			if(this.isPlayerClick(tipo))
				this.valutazioneGiocatore.push(tipo);
		},
		addValutazionePartita: function(tipo) {
			if(this.isMatchClick(tipo))
				this.valutazionePartita.push(tipo);
		}
	},
	computed: {
		typeOfPlayerValutations: function() {
			
			return _.filter(ValutazioniPlayer, function(i) {
				return i.role === null || i.role.indexOf(this.player.role) !== -1;
			}.bind(this));

		}
	},
	data: function() {
		return {
			ux: {
				show: false
			},
			valutazioneGiocatore: [],
			valutazionePartita: []
		}
	},
	mounted: function() {
		// this.$refs.mod.show();
	}
}
</script>