<template>
	<div>
		<b-modal 
            size="full" 
            scrollable 
			centered
            v-model="ux.show" 
            ok-only ok-title="Chiudi"
            :title="`Tool analysis date selezionate`"
            @hide="close()" @close="close()" @ok="close()" @cancel="close()" 
        >
			<div>
                <ToolsAnalysis
                    :customDateFrom="customDateFrom"
                    :customDateTo="customDateTo"
                    :hideDates="true"
                    :sessionIds="sessionIds"
                />	
            </div>
			<template #modal-footer>
				<b-row class="w-100">
					<b-col class="text-right">
						<b-button-group>
							<b-button class="bg-secondary" @click.prevent="close()">Chiudi</b-button>
						</b-button-group>
					</b-col>
				</b-row>
			</template>
		</b-modal>
	</div>
</template>

<script>

import _ from 'lodash'
var Auth = require('@/auth.js');

export default {
	mixins: [],
	components: {
		ToolsAnalysis: require('@/components/layout/Sessioni/Generica/Tools_Table.vue').default
	},
	watch: {
		'ux.show': {
			deep: true,
			handler: function() {}
		}
	},
	created: function() {
	},
	props: [ 'customDateFrom', 'customDateTo', 'sessionIds' ],
	data: function() {
		return {
			ux: {
				show: true,
				wait: false
			}
		}
	},
	
	computed: {
		auth: function() {
			return Auth;
		},

		_: function() {
			return _;
		}
	},
	methods: {
		close: function() {
			this.ux.show = false;
			this.$emit('close');
		},
		show: function() {
			this.ux.show = true;
		},
		getDensitaCampo(phase) {
			var densita = "";
			if(phase.players > 0 && phase.campo_lunghezza > 0 && phase.campo_larghezza > 0) {
				densita = (phase.campo_lunghezza * phase.campo_larghezza) / phase.players;
				densita = Math.round(densita * 100) / 100;
			}
			return densita;
		},
	}
}

</script>
