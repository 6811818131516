<template>
	<div>
		<b-card>
			<template slot="header">Impact on the game</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Resolving problems created by the opponent</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_2, kpis.val_3, kpis.val_4, kpis.val_5, kpis.val_6 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO PRESS AND HARASS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_2"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>2ND BALLS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_3"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>REACTION AT BALL LOSS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_4"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>DEFENDING QUICK TRANSITIONS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_5"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>SUPPORTING HIS FULL BACK / TRACKING BACK</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_6"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Creating problems to the opponent</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_8, kpis.val_9, kpis.val_10, kpis.val_11, kpis.val_12, kpis.val_13, kpis.val_14 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>ATTACKING THE SPACE IN BEHIND</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_8"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>COMBINATION PLAY</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_9"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-th>CAPACITY TO ELIMINATE IN 1VS1 SITUATIONS (OUTSIDE / INSIDE)</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_10"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO BE CREATIVE</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_11"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO ATTACK WIDE</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_12"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO ATTACK INSIDE</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_13"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO BE DECISIVE</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_14"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Interpretation of game situations</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_16, kpis.val_17, kpis.val_18, kpis.val_19 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>SCANNING / AWARENESS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_16"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>COMPOSURE UNDER PRESSURE</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_17"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>DECISION MAKING</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_18"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>MOVEMENTS AND CAPACITY TO LOSE THE MARK (INSIDE AND OUTSIDE THE BOX)</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_19"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Physical Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>PACE/ TURN OF PACE / ACCELERATION</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_20"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>ACTIVATION / REACTIONS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_21"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Power</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_22"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO IMPOSE HIMSELF IN CONTACT DUELS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_23"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO REPEAT HIGH INTENSITY RUNS / GENEROSITY</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_24"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>IN THE AIR</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_25"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>COOORDINATION / BALANCE / AGILITY</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_26"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Technical Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Receiving skills / Quality of the 1st touch</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_27"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Ball manipulation</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_28"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Passing range (short-medium-long)</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_29"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Attacking end product</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_30"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>DEFENSIVE CLEARANCES</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_31"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>USE OF WEAKER FOOT</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_32"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>SET PIECES DELIVERIES</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_33"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Psychological Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Interactions with the different actors of the game</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_34"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Body language</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_35"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Reaction after key moments</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_36"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Presence / Aura</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_37"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
	</div>
</template>

<script>

export default {
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
	},
	methods: {
		mean: function(arr) {
			return _.mean(_.filter(arr));
		}
	},
	data: function() {
		return {
			kpis: {
				val_1: null,
				val_2: null,
				val_3: null,
				val_4: null,
				val_5: null,
				val_6: null,
				val_7: null,
				val_8: null,
				val_9: null,
				val_10: null,
				val_11: null,
				val_12: null,
				val_13: null,
				val_14: null,
				val_15: null,
				val_16: null,
				val_17: null,
				val_18: null,
				val_19: null,
				val_20: null,
				val_21: null,
				val_22: null,
				val_23: null,
				val_24: null,
				val_25: null,
				val_26: null,
				val_27: null,
				val_28: null,
				val_29: null,
				val_30: null,
				val_31: null,
				val_32: null,
				val_33: null,
				val_34: null,
				val_35: null,
				val_36: null,
				val_37: null
			}
		}
	}
}

</script>