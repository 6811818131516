<template>
	<div>
		<b-overlay :show="loading">
			<b-form @submit="search">
				<b-input-group>
					<b-form-input v-model="form.name" required placeholder="Squadra o Giocatore"></b-form-input>
					<b-input-group-append>
						<b-button type="submit" variant="primary"><i class="fa fa-search" aria-hidden="true"></i></b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form>
			<div v-if="results" class="mt-2">
				<b-card title="Squadre" v-if="results.squadre && results.squadre.length > 0" class="mb-1">
					<b-row>
						<b-col sm="1" v-for="squadra in results.squadre" style="padding: 0.2rem">
							<b-card class="small text-center" style="height:100%">
								<router-link :to="{ name: 'scout_squadra', params: { squadra_id: squadra.id } }" target="_blank">
									<b-img :src="squadra.logo_url" fluid :alt="squadra.officialName" :width="120"/>
								</router-link>
								<div><strong>{{squadra.officialName}}</strong></div>
								<div v-if="squadra.categoria" class="font-size-xs color-rosso">{{ squadra.categoria.name }}</div>
								<div v-if="squadra.paese" class="font-size-xs"><flag :country="squadra.paese ? squadra.paese.sigla : ' '" :size="16"></flag> {{ squadra.paese.paese }}</div>
							</b-card>
						</b-col>
					</b-row>
				</b-card>
				<b-card title="Giocatori" v-if="results.giocatori && results.giocatori.length > 0" class="mb-1">
					<b-row>
						<b-col sm="1" v-for="giocatore in results.giocatori" style="padding: 0.2rem">
							<b-card class="small text-center" style="height:100%">
								<router-link v-if="giocatore.last_carriera_sportiva && giocatore.last_carriera_sportiva.squadra_id" :to="{ name: 'scout_squadra', params: { squadra_id: giocatore.last_carriera_sportiva.squadra_id } }" target="_blank">
									<b-img :src="giocatore.avatar_url" fluid :width="120"/>
								</router-link>
								<b-img v-else :src="giocatore.avatar_url" fluid :width="120"/>
								<div><strong>{{ giocatore.cognome }} {{ giocatore.nome }}</strong></div>
								<div v-if="giocatore.nascita_data" class="font-size-xs">Classe {{ giocatore.nascita_data | formatYear }} ({{ giocatore.nascita_data | age }} anni)</div>
								<div v-if="giocatore.last_carriera_sportiva && giocatore.last_carriera_sportiva.squadra" class="font-size-xs color-rosso">{{ giocatore.last_carriera_sportiva.squadra.officialName }}</div>
							</b-card>
						</b-col>
					</b-row>
				</b-card>
			</div>
		</b-overlay>
	</div>
</template>

<script>

import moment from 'moment'
export default {

	data: function() {
		return {
			loading: false,
			form: {
				name: null,
			},
			results: null,
		}
	},
	components: {
		flag: require('@/components/layout/flag.vue').default,
	},
	watch: {
	},
	computed: {
	},
	methods: {
		search: function() {
			this.loading = true;
			this.$http.post(this.$store.state.apiEndPoint + '/scout/search', this.form).then(function(res) {
				this.results = res.data;
				this.loading = false;
			});
		}
	},
	filters: {
        formatYear: function (date) {
            var m = moment(date);
            if(m.isValid()) {
                return m.format('YYYY'); 
            }
            return "";
        },
        formatDate: function (date) {
            var m = moment(date);
            if(m.isValid()) {
                return m.format('DD/MM/YY'); 
            }
            return "";
        },
        age: function (date) {
            var birthday = moment(date);
            if(birthday.isValid()) {
                return moment().diff(birthday, 'years');
            }
            return "";
        },
    },

}

</script>