<template>
	<div>
		<b-card>
			<template slot="header">Impact on the game</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Resolving problems created by the opponent</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_2, kpis.val_3, kpis.val_4, kpis.val_5, kpis.val_6, kpis.val_7, kpis.val_8 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Defending 1vs1 situations (short and long)</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_2"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to dispossess</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_3"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Defending opponent back to goal</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_4"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Defending the space in behind</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_5"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Defending the box</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_6"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Reaction at ball loss</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_7"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Defending quick transitions</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_8"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Creating problems to the opponent</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_10, kpis.val_11, kpis.val_12, kpis.val_13, kpis.val_14, kpis.val_15, kpis.val_16 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Angles to receive</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_10"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Impact on ball circulation (continuity / verticality)</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_11"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Combination play</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_12"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to eliminate</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_13"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to attack wide</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_14"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to attack inside</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_15"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to be decisive</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_16"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Interpretation of game situations</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_18, kpis.val_19, kpis.val_20, kpis.val_21, kpis.val_22, kpis.val_23, kpis.val_24, kpis.val_25 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Reaction in open spaces</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_18"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Marking</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_19"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to anticipate / step in front of his man</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_20"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Coverings</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_21"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Scanning / Awareness</b-td>
							<b-td><Smart-Tag v-model="kpis.val_22"></Smart-Tag></b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Movements and capacity to lose the mark</b-td>
							<b-td><Smart-Tag v-model="kpis.val_23"></Smart-Tag></b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Composure under pressure</b-td>
							<b-td><Smart-Tag v-model="kpis.val_24"></Smart-Tag></b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Decision making</b-td>
							<b-td><Smart-Tag v-model="kpis.val_25"></Smart-Tag></b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Physical Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Pace/Turn of pace/Acceleration</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_26"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Activation / Reactions</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_27"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Power</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_28"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to impose himself in contact duels</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_29"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to repeat high intensity runs / generosity</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_30"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>In the air</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_31"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Cooordination/Balance/Agility</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_32"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Technical Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Receiving skills / Quality of the 1st touch</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_33"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Ball manipulation</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_34"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Passing range (short-medium-long)</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_35"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Attacking end product</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_36"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Defensive clearances</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_37"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Use of weaker foot</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_38"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Set pieces deliveries</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_39"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Psychological Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Interactions with the different actors of the game</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_40"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Body language</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_41"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Reaction after key moments</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_42"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Presence / Aura</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_43"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			kpis: {
				val_1: null,
				val_2: null,
				val_3: null,
				val_4: null,
				val_5: null,
				val_6: null,
				val_7: null,
				val_8: null,
				val_9: null,
				val_10: null,
				val_11: null,
				val_12: null,
				val_13: null,
				val_14: null,
				val_15: null,
				val_16: null,
				val_17: null,
				val_18: null,
				val_19: null,
				val_20: null,
				val_21: null,
				val_22: null,
				val_23: null,
				val_24: null,
				val_25: null,
				val_26: null,
				val_27: null,
				val_28: null,
				val_29: null,
				val_30: null,
				val_31: null,
				val_32: null,
				val_33: null,
				val_34: null,
				val_35: null,
				val_36: null,
				val_37: null,
				val_38: null,
				val_39: null,
				val_40: null,
				val_41: null,
				val_42: null,
				val_43: null
			}
		}
	},
	methods: {
		mean: function(arr) {
			return _.mean(_.filter(arr));
		}
	},
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
	}
}

</script>