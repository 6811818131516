<template>
	<div>
		<b-overlay no-wrap :show="loading"></b-overlay>
		<b-card>
			<b-row :key="random">
				<b-col>
					<b-card v-for="kpi,k in kpi_selected" class="mb-2" v-if="!loading">
						<b-row>
							<b-col cols="1">
								<span class="small text-muted">{{ getLabelKpi(kpi) }}</span>
							</b-col>
							<b-col cols="11">
								<E-Chart @onClick="setFocus" style="width: 100%; height: 300px" :option="graphOption[k]"></E-Chart>
								<div class="text-right" v-if="level == 'player' && !hasDetailPlayer(kpi) && keyLength > 1"><b-button size="sm" @click="showDetailPlayer(kpi)">Dettaglio giocatori</b-button></div>
								<div v-if="hasDetailPlayer(kpi)" class="mt-2">
									<b-row v-for="person_id in thePersonIds" class="border mb-2">
										<b-col>
											<div class="text-center mt-2 font-weight-bold" style="font-size:0.8rem;">{{ getPerson(person_id).cognome + ' ' + getPerson(person_id).nome }}</div>
											<E-Chart style="width: 100%; height: 300px" :option="graphOptionByPerson[person_id][k]"></E-Chart>
										</b-col>
									</b-row>
									<div class="text-right">
										<b-button size="sm" @click="hideDetailPlayer(kpi)">Chiudi dettaglio</b-button>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
				<b-col cols="2" class="pl-3">
					<b-form-group label="Visualizza">
						<b-form-radio-group size="sm" v-model="show"><b-form-radio value="media">Medie</b-form-radio><b-form-radio value="totale">Totali</b-form-radio><b-form-radio value="somma_medie">Somma Medie</b-form-radio></b-form-radio-group>
					</b-form-group>
					<b-form-group label="Aggrega per">
						<b-form-radio-group size="sm" v-model="aggregate"><b-form-radio value="day">Giorno</b-form-radio><b-form-radio value="week">Microciclo</b-form-radio><b-form-radio value="calendar_week">Settimana</b-form-radio></b-form-radio-group>
					</b-form-group>
					<b-form-group label="Livello di dettaglio">
						<b-form-radio-group size="sm" v-model="level"><b-form-radio value="team">Team</b-form-radio><b-form-radio value="player" :disabled="filters.players.length == 0">Giocatore</b-form-radio></b-form-radio-group>
					</b-form-group>
					<b-form-group label="Ordinamento valori" v-if="level == 'player' && keyLength == 1">
						<b-form-radio-group size="sm" v-model="sortBy"><b-form-radio value="none">Nessuno</b-form-radio><b-form-radio value="desc">Descrescente</b-form-radio><b-form-radio value="asc">Crescente</b-form-radio></b-form-radio-group>
					</b-form-group>
					<b-form-group label="Medie">
						<b-form-checkbox-group size="sm" v-model="medie"><b-form-checkbox value="periodo">Media Team</b-form-checkbox><b-form-checkbox value="media">Media Giocatori Selezionati</b-form-checkbox><b-form-checkbox value="aggregati">Media Team Giornaliera</b-form-checkbox></b-form-checkbox-group>
					</b-form-group>
					<div v-if="filters.players.length == 1" class="mb-3">
						<b-form-group label="Giocatore">
							<b-form-checkbox size="sm" v-model="showBestKpi">85% Best Performance</b-form-checkbox>		
						</b-form-group>
						<b-row align-v="center">
							<b-col cols="1" class="text-right">
								Dal
							</b-col>
							<b-col cols="5">	
								<b-form-input size="sm" type="date" v-model="bestKpiFrom"></b-form-input>
							</b-col>
							<b-col cols="1" class="text-right">
								Al
							</b-col>
							<b-col cols="5">
								<b-form-input size="sm" type="date" v-model="bestKpiTo"></b-form-input>
							</b-col>
						</b-row>
					</div>

					<div v-if="showEditor">

						<b-checkbox-group v-if="ux.showEditor" value-field="value" v-model="kpi_selected" :options="kpi_options" size="sm"></b-checkbox-group>

						<b-button size="sm" variant="outline-secondary" @click.prevent="ux.showEditor = !ux.showEditor">Modifica KPI</b-button>

					</div>

				</b-col>
			</b-row>
		</b-card>

		<b-sidebar right width="700px" :visible="ux.weekFocus !== null" @hidden="ux.weekFocus = null">
			<div class="p-2" v-if="ux.weekFocus !== null">
				<ToolsChartDetail :kpi_options="kpi_options" :results="results" :days="days" :dataset="datasetWeekFiltered" :kpi_selected="kpi_selected" :level="level" :person_ids="thePersonIds" :dataset_by_person="datasetWeekFilteredByPerson" :matchCalendar="matchCalendar"></ToolsChartDetail>
			</div>
		</b-sidebar>

	</div>
</template>

<script>

import toolsHelper from '@/tools_helper.js'

function buildKpiValues(dati, ds = null) {

	var out = {};

	out = _.merge({}, toolsHelper.buildNeurom(dati, ds));
	out = _.merge(out, toolsHelper.buildDrill(dati, ds));
	out = _.merge(out, toolsHelper.buildVelocity(dati, ds));
	out = _.merge(out, toolsHelper.buildHeartRate(dati, ds));
	
	out.week = _.values(dati)[0].week;
	out.calendar_week = _.values(dati)[0].calendar_week;
	out.date = _.values(dati)[0].date;

	return _.cloneDeep(out);

}

var Auth = require('@/auth.js');
import moment from 'moment';
import eChart from '@/components/layout/E-Chart/Base.vue';
export default {
	props: [ 'dataset', 'results', 'days', 'show-editor', 'preset', 'filters', 'datasetNopeople', 'allDataset', 'theTables', 'matchCalendar', 'daysOffShow', 'daysOffKpi','daysOff', 'daysOffByPerson', 'categoriaId' ],
	components: {
		'E-Chart': eChart,
		ToolsChartDetail: require('@/components/layout/Sessioni/Generica/Tools_Chart_Detail.vue').default
	},
	data: function() {
		return {
			kpi_options: [
				{value: 'gps_minutes', text: 'GPS Minutes'},
				{value: 'total_distance', text: 'Total Distance'},
				{value: 'relative_distance', text: 'Relative Distance'},
				{value: 'cc_dist_15', text: 'Dist > 15'},
				{value: 'max_vel', text: 'Max Velocity'},
				{value: 'mp', text: 'MP'},
				{value: 'edi', text: 'EDI'},
				{value: 'pow_ev', text: 'Pow. Ev.'},
				{value: 'num_acc', text: 'Acc'},
				{value: 'num_dec', text: 'Dec'},
				{value: 'sp_ev', text: 'Sp. Ev.'},
				{value: 'edward', text: 'Edwards TL'},
				{value: 'session_minutes', text: 'Session Minutes'},
				{value: 'rpe', text: 'RPE'},
				{value: 'sessione_tl', text: 'Session TL'},
				{value: 'tqr', text: 'TQR'},

				{value: 'velocity_0_8', text: '0-8 km/h'},
				{value: 'velocity_8_13', text: '8-13 km/h'},
				{value: 'velocity_13_15', text: '13-15 km/h'},
				{value: 'velocity_15_20', text: '15-20 km/h'},
				{value: 'velocity_20_25', text: '20-25 km/h'},
				{value: 'velocity__25', text: '>25 km/h'},
				{value: 'velocity__15', text: '>15 km/h'},
				{value: 'velocity__20', text: '>20 km/h'},
				{value: 'velocity__15_p', text: '> 15 km/h %'},

				{value: 'vel_max_perc_0_60', text: '0-60% Max Vel'},
				{value: 'vel_max_perc_60_70', text: '60-70% Max Vel'},
				{value: 'vel_max_perc_70_80', text: '70-80% Max Vel'},
				{value: 'vel_max_perc_80_90', text: '80-90% Max Vel'},
				{value: 'vel_max_perc_90_100', text: '90-100% Max Vel'},
				{value: 'vel_max_perc__100', text: '>100% Max Vel'},
				{value: 'vel_max_perc__80', text: '>80% Max Vel'},
				{value: 'vel_max_perc__90', text: '>90% Max Vel'},
				{value: 'vel_max_perc__80_p', text: '>80% Max Vel %'},

				{value: 'acc1', text: 'Acc 1'},
				{value: 'acc2', text: 'Acc 2'},
				{value: 'acc3', text: 'Acc 3'},
				{value: 'dec3', text: 'Dec 3'},
				{value: 'dec2', text: 'Dec 2'},
				{value: 'dec1', text: 'Dec 1'},

				{value: 'avg_hr', text: 'AVG HR'},
				{value: 'avg_hr_p', text: 'AVG HR %'},
				{value: 'max_hr', text: 'Max HR'},
				{value: 'max_hr_p', text: 'Max HR %'},
				{value: 'hr_0_55', text: 'HR 0-55 %'},
				{value: 'hr_55_65', text: 'HR 55-65 %'},
				{value: 'hr_65_75', text: 'HR 65-75 %'},
				{value: 'hr_75_85', text: 'HR 75-85 %'},
				{value: 'hr_85_95', text: 'HR 85-95 %'},
				{value: 'hr_95', text: 'HR 95 %'},
				{value: 'hr_85', text: 'HR 85 %'},
				{value: 'hr_85_raw', text: 'HR 85'},
				],
			kpi_selected: null,

			show: 'media',
			aggregate: 'day',
			level: 'team',
			medie: ['periodo'],
			sortBy: 'none',
			ux: {
				weekFocus: null,
				showEditor: false,
				detailPlayer: [],
			},
			random: Math.random(),
			loading: true,
			showBestKpi: false,
			bestKpiLoading: false,
			bestKpiByPlayer: {},
			bestKpiFrom: null,
			bestKpiTo: null,
		}
	},

	watch: {
		'filters.players': function() {
			if(this.filters.players.length == 0) {
				this.level = 'team';
			}
		},

		daysOffShow: function() {
			this.random = Math.random();
		},

		dataset: function() {
			this.random = Math.random();
		},

		medie: function() {
			this.random = Math.random();
		},

		level: function() {
			if(this.level == 'team' && this.ux.detailPlayer.length > 0) {
				this.ux.detailPlayer = [];
			}
			this.random = Math.random();
		}, 

		aggregate: function() {
			this.random = Math.random();
		},
		'preset': {
			immediate: true,
			handler: function() {
				if(this.preset === null || this.preset == undefined || this.preset.length == 0)
					this.kpi_selected = ['total_distance', 'velocity_20_25', 'velocity__25', 'num_acc', 'num_dec', 'rpe'];
				else
					this.kpi_selected = this.preset;

				if(this.keyLength == 1 && this.filters.players.length > 0) {
					this.level = 'player';
				}
			}
		},
		'kpi_selected': {
			immediate: true,
			handler: function() {
				if(this.filters.players.length == 1 && this.showBestKpi) {
				//	if(!this.bestKpiByPlayer[this.filters.players[0]]) {
						this.getBestKpiPlayer(this.filters.players[0]);
					}
			//	}
			}
		},
		showBestKpi: function() {
			if(this.showBestKpi && this.filters.players.length == 1) {
			//	if(!this.bestKpiByPlayer[this.filters.players[0]]) {
					this.getBestKpiPlayer(this.filters.players[0]);
			//	}
			}
		},
		isShowBestKpi: function() {
			this.random = Math.random();
		},

		bestKpiFrom: function() {
			if(this.showBestKpi && this.filters.players.length == 1) {
				this.getBestKpiPlayer(this.filters.players[0]);
			}
		},
		bestKpiTo: function() {
			if(this.showBestKpi && this.filters.players.length == 1) {
				this.getBestKpiPlayer(this.filters.players[0]);
			}
		},
	},

	computed: {
		user: function() {
			return Auth.user;
		},
		isShowBestKpi: function() {

			console.log(this.showBestKpi);
			console.log(this.filters.players.length == 1);
			console.log(typeof(this.bestKpiByPlayer[this.filters.players[0]]) !== 'undefined');
			console.log(!this.bestKpiLoading);

			return this.showBestKpi && this.filters.players.length == 1 && typeof(this.bestKpiByPlayer[this.filters.players[0]]) !== 'undefined' && !this.bestKpiLoading;
		},
		keyLength: function() {
			return _.keys(this.theDataset).length;
		},

		datasetWeekFiltered: function() {
			if(this.aggregate == 'week') 
				return _.filter(this.theDatasetAlwaysDate, {week: this.ux.weekFocus});
			else 
			return _.filter(this.theDatasetAlwaysDate, {calendar_week: this.ux.weekFocus});

		},

		datasetWeekFilteredByPerson: function() {
			if(this.aggregate == 'week') 
				return _.mapValues(this.theDatasetAlwaysDateByPerson, function(items) { return _.filter(items, {week: this.ux.weekFocus}) }.bind(this));
			else 
			return _.mapValues(this.theDatasetAlwaysDateByPerson, function(items) { return _.filter(items, {calendar_week: this.ux.weekFocus}) }.bind(this));
		},

		thePersonIds: function() {
			return _.keys(this.theDatasetByPerson);
		},

		theDatasetAlwaysDate: function() {

			var dataset = this.dataset;

			dataset = _.orderBy(dataset, [function(a) {
				return moment(a.date).format('X');
			}, 'sessione_id'], [ 'asc', 'asc' ]);

			_.each(dataset, function(a) {

				var sessione = _.find(this.results.sessioni, { id: a.sessione_id });

				var week = 1;
				var calendar_week = 1;
				_.each(this.days, function(b) {
					var data = b.split(' ');
					if(data[1] && data[1].length > 0) {
						if(moment(a.date).format('x') > moment(data[0]).format('x')) {
							week++;
						}
					}
					if(moment(data[0]).day() == 0) {
						if(moment(a.date).format('x') > moment(data[0]).format('x')) {
							calendar_week++;
						}
					}
				}.bind(this));
				a.week = 'Week ' + week;
				a.calendar_week = 'Week ' + calendar_week;

			}.bind(this));

			dataset = _.groupBy(dataset, 'date');

			dataset = _.mapKeys(dataset, function (value, key) {
				var dayVal = this.matchCalendar[key];
				return key + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
			}.bind(this));	

			var ds = null;
			if(this.allDataset)
				ds = this.results;
			
			if(this.show == 'media' || this.show == 'somma_medie') {

				var data = _.mapValues(dataset, function(a) {

					var gp = _.groupBy(a, 'persona_id');

					var kpiBuilt = _.mapValues(gp, function(b) {
						var c = buildKpiValues(b, ds);
						c.sessione_id = b.sessione_id;
						c.persona_id = b.persona_id;
						return c;
					});


					var objToReturn = {};
					var keys = _.keys(_.first(_.values(kpiBuilt)));
					_.each(keys, function(key) {
						if(key !== 'week' && key !== 'date' && key !== 'calendar_week')
							objToReturn[key] = _.size(_.values(kpiBuilt).filter(item => item[key] != 0)) > 0 ? _.round(_.sumBy(_.values(kpiBuilt), key) / _.size(_.values(kpiBuilt).filter(item => item[key] != 0)), 0) : 0;
						else {
							objToReturn[key] = _.values(kpiBuilt)[0][key];
						}
					});

					return objToReturn;

				});

				return data;

			} else {
				return _.mapValues(dataset, function(a) {
					return buildKpiValues(a, ds);
				});
			}

		},

		theDatasetAlwaysDateByPerson: function() {

			var dataset = this.dataset;

			dataset = _.orderBy(dataset, [function(a) {
				return moment(a.date).format('X');
			}, 'sessione_id'], [ 'asc', 'asc' ]);

			_.each(dataset, function(a) {

				var sessione = _.find(this.results.sessioni, { id: a.sessione_id });

				var week = 1;
				var calendar_week = 1;
				_.each(this.days, function(b) {
					var data = b.split(' ');
					if(data[1] && data[1].length > 0) {
						if(moment(a.date).format('x') > moment(data[0]).format('x')) {
							week++;
						}
					}
					if(moment(data[0]).day() == 0) {
						if(moment(a.date).format('x') > moment(data[0]).format('x')) {
							calendar_week++;
						}
					}
				}.bind(this));
				a.week = 'Week ' + week;
				a.calendar_week = 'Week ' + calendar_week;

			}.bind(this));


			dataset = _.groupBy(dataset, 'persona_id');

			dataset =  _.mapValues(dataset, function(a) {
				var ds = _.groupBy(a, 'date');
				return _.mapKeys(ds, function (value, key) {
					var dayVal = this.matchCalendar[key];
					return key + ' (' + (isNaN(dayVal) ? 'M' : dayVal) + ')';
				}.bind(this));	
			}.bind(this));
			

			var ds = null;
			if(this.allDataset)
				ds = this.results;
			
			if(this.show == 'media' || this.show == 'somma_medie') {

				var data = _.mapValues(dataset, function(a) {

					var kpiBuilt = _.mapValues(a, function(b) {

						var c = buildKpiValues(b, ds);
						c.sessione_id = b.sessione_id;
						c.persona_id = b.persona_id;
						return c;
					});
					return kpiBuilt;
				});

				return data;

			} else {
				return _.mapValues(dataset, function(a) {
					return _.mapValues(a, function(b) {
						return buildKpiValues(b, ds);
					});
				});
			}
		},

		theDataset: function() {

			if(this.aggregate == 'day')
				return this.theDatasetAlwaysDate;

			var p = _.groupBy(_.values(this.theDatasetAlwaysDate), this.aggregate);
			var d = _.mapValues(p, function(a) {
				var output = {};
				_.each(_.keys(a[0]), function(b) {

					var values = _.map(a, b);
					var valid = _.filter(values, function(c) {
						return !_.isNumber(c);
					}).length;

					if(valid == 0) {
						if(this.show == 'media') {
							output[b] = _.size(values.filter(item => item != 0)) > 0 ? _.round(_.sum(values) / _.size(values.filter(item => item != 0)), 0) : 0;
						} else {
							output[b] = _.round(_.sum(values), 0);
						}
					} else {
						output[b] = values[0];
					}

				}.bind(this));
				return output;
			}.bind(this));
			return d;
		},

		theDatasetByPerson: function() {
			if(this.aggregate == 'day')
				return this.theDatasetAlwaysDateByPerson;
			var data = _.mapValues(this.theDatasetAlwaysDateByPerson, function(dataset) {
				var p = _.groupBy(_.values(dataset), this.aggregate);
				var d = _.mapValues(p, function(a) {
					var output = {};
					_.each(_.keys(a[0]), function(b) {

						var values = _.map(a, b);
						var valid = _.filter(values, function(c) {
							return !_.isNumber(c);
						}).length;

						if(valid == 0) {
							if(this.show == 'media') {
								output[b] = _.size(values.filter(item => item != 0)) > 0 ? _.round(_.sum(values) / _.size(values.filter(item => item != 0)), 0) : 0;
							} else {
								output[b] = _.round(_.sum(values), 0);
							}
						} else {
							output[b] = values[0];
						}

					}.bind(this));
					return output;
				}.bind(this));
				return d;
			}.bind(this));
			return data;
		},

		theResults: function() {

			return this.datasetNopeople;

			return ds;
		},
		theFullDataset: function() {

			var dataset = this.theResults;

			dataset = _.orderBy(dataset, [function(a) {
				return moment(a.date).format('X');
			}, 'sessione_id'], [ 'asc', 'asc' ]);

			_.each(dataset, function(a) {

				var sessione = _.find(this.results.sessioni, { id: a.sessione_id });

				var week = 1;
				var calendar_week = 1;
				_.each(this.days, function(b) {
					var data = b.split(' ');
					if(data[1] && data[1].length > 0) {
						if(moment(a.date).format('x') > moment(data[0]).format('x')) {
							week++;
						}
					}
					if(moment(data[0]).day() == 0) {
						if(moment(a.date).format('x') > moment(data[0]).format('x')) {
							calendar_week++;
						}
					}
				}.bind(this));
				a.week = 'Week ' + week;
				a.calendar_week = 'Week ' + calendar_week;

			}.bind(this));

			dataset = _.groupBy(dataset, 'date');

			var ds = null;
			if(this.allDataset)
				ds = this.results;
			
			if(this.show == 'media' || this.show == 'somma_medie') {

				var data = _.mapValues(dataset, function(a) {

					var gp = _.groupBy(a, 'persona_id');

					var kpiBuilt = _.mapValues(gp, function(b) {
						var c = buildKpiValues(b, ds);
						c.sessione_id = b.sessione_id;
						c.persona_id = b.persona_id;
						return c;
					});


					var objToReturn = {};
					var keys = _.keys(_.first(_.values(kpiBuilt)));
					_.each(keys, function(key) {
						if(key !== 'week' && key !== 'date' && key !== 'calendar_week')
							objToReturn[key] = _.size(_.values(kpiBuilt).filter(item => item[key] != 0)) > 0 ? _.round(_.sumBy(_.values(kpiBuilt), key) / _.size(_.values(kpiBuilt).filter(item => item[key] != 0)), 0) : 0;
						else
							objToReturn[key] = _.values(kpiBuilt)[0][key];
					});

					return objToReturn;

				});

				if(this.aggregate != 'day') {
					var p = _.groupBy(_.values(data), this.aggregate);
					var d = _.mapValues(p, function(a) {
						var output = {};
						_.each(_.keys(a[0]), function(b) {

							var values = _.map(a, b);
							var valid = _.filter(values, function(c) {
								return !_.isNumber(c);
							}).length;

							if(valid == 0) {
								if(this.show == 'media') {
									output[b] = _.size(values.filter(item => item != 0)) > 0 ? _.round(_.sum(values) / _.size(values.filter(item => item != 0)), 0) : 0;
								} else {
									output[b] = _.round(_.sum(values), 0);
								}
							} else {
								output[b] = values[0];
							}
						}.bind(this));
						return output;
					}.bind(this));
					return d;
				}

				return data;

			} else {
				return _.mapValues(dataset, function(a) {
					return buildKpiValues(a, ds);
				});
			}

		},

		graphOption: function() {

			var colors = [
				'#c23531',
				'#2f4554',
				'#61a0a8',
				'#d48265',
				'#91c7ae',
				'#749f83',
				'#ca8622',
				'#bda29a',
				'#6e7074',
				'#546570',
				'#c4ccd3',
				'#c23531',
				'#2f4554',
				'#61a0a8',
				'#d48265',
				'#91c7ae',
				'#749f83',
				'#ca8622',
				'#bda29a',
				'#6e7074',
				'#546570',
				'#c4ccd3'
				];

			var ds = this.theDataset;
			var ds2 = this.theFullDataset;
			var options = [];
			var legend = [];

			_.each(this.kpi_selected, function(kpi, u) {

				var series = [];
				var xData = [];

				var dataMedia = _.map(ds2, kpi);
				if(this.level == 'player' && _.keys(ds).length == 1) {
					dataMedia = _.map(this.thePersonIds, function() {
						return _.map(ds2, kpi)[0];
					});
				}

				if(this.medie.includes('aggregati')) {				
					var serieMedia = {
						data: dataMedia,
						type: 'bar',
						name: this.getLabelKpi(kpi),
						label: {
							show: false
						},
						itemStyle: {
							normal: {
								color: '#ddd',
							}
						},
						barWidth: '60%',
						tooltip: {
							formatter: function (params) {
								return 'Team Average: <strong>' + params.value + '</strong>';
							}
						}
					};
					series.push(serieMedia);
				}

				var max_ds = _.max(_.map(ds, kpi));

				if(this.level == 'team') {
					var serie = {
						data: this.getSerie(ds, kpi),
						type: 'bar',
						name: this.getLabelKpi(kpi),
						color: [
							colors[u]
							],
						label: {
							show: true,
							position: 'top'
						},
						z: 4,
					};

					if(this.medie.includes('periodo')) {
						serie.markLine = {
							symbol: 'none',
							silent: true,
							data: [ { symbol: null, symbolSize: 0, yAxis: _.round(_.sum(_.map(ds2, kpi))/_.size(_.filter(_.map(ds2, kpi)))) } ]
						}

					}
					if(this.medie.includes('aggregati')) {

						serie.barWidth = '30%';
						serie.barGap = '-75%';
					}
					series.push(serie);
				} else {
					if(_.keys(ds).length > 1) {
						var index = 0;
						_.forEach(this.theDatasetByPerson, function(dsp, person_id) {
							var values = this.getSerieByPerson(dsp, kpi, person_id);
							var label = this.getPerson(person_id).cognome;
							var serie = {
								data: values,
								type: 'line',
								name: label,
								label: {
									show: true,
									position: 'top'
								},
								z: 10,
								itemStyle: {
									color: colors[index],
								},
							};

							legend.push(label);

							if(_.max(values) > max_ds) {
								max_ds = _.max(values);
							}
							series.push(serie);
							index++;
						}.bind(this));
					} else {
						var data = [];
						_.forEach(this.theDatasetByPerson, function(dsp, person_id) {
							var value = _.map(dsp, kpi)[0];
							data.push({person_id: person_id, value: value});
						}.bind(this));

						if(_.max(_.map(data, 'value')) > max_ds) {
							max_ds = _.max(_.map(data, 'value'));
						}

						if(this.sortBy == 'desc') {
							data = _.sortBy(data, 'value').reverse();
						} else if(this.sortBy == 'asc') {
							data = _.sortBy(data, 'value');
						}

						var serie = {
							data: _.map(data, 'value'),
							type: 'bar',
							name: this.getLabelKpi(kpi),
							color: [
								colors[u]
								],
							label: {
								show: true,
								position: 'top'
							},
							z: 10,
						};

						if(this.medie.includes('aggregati')) {
							serie.barWidth = '30%';
							serie.barGap = '-75%';
						}

						series.push(serie);

						_.map(data, function(item) {
							var label = this.getPerson(item.person_id).cognome; 
							xData.push(label);	
						}.bind(this));
					}

					if(this.medie.includes('periodo')) {
						series[0].markLine = {
							symbol: 'none',
							silent: true,
							data: [ { symbol: null, symbolSize: 0, yAxis: _.round(_.sum(_.map(ds2, kpi))/_.size(_.filter(_.map(ds2, kpi)))) } ]
						}
					}
				}

				if(this.medie.includes('media')) {

					series[0].stack = 'ciao';

					series.push({
						data: _.map(ds, function() { return null; }), 
						type: 'bar', 
						stack: 'ciao', 
						name: this.getLabelKpi(kpi),
						markLine: {
							symbol: 'none',
							silent: true,
							data: [ { symbol: null, symbolSize: 0, yAxis: _.round(_.sum(_.map(ds, kpi))/_.size(_.filter(_.map(ds, kpi)))) } ]
						}
					});
				}

				if(this.isShowBestKpi) {
				//	console.log('kpi: ' + kpi);
				//	console.log('yAxis: ' + this.bestKpiByPlayer[this.filters.players[0]][kpi]);

			/*		series[0].markLine = {
						symbol: 'none',
						silent: true,
						data: [ { symbol: null, symbolSize: 0, yAxis: this.bestKpiByPlayer[this.filters.players[0]][kpi] } ]
					}  */

					series[0].stack = 'best';

					series.push({
						data: _.map(ds, function() { return null; }), 
						type: 'line', 
						stack: 'best', 
						name: this.getLabelKpi(kpi),
						markLine: {
							lineStyle: {
								normal: {
									type:'solid',
									color: 'blue',
								}
							},
							label:{
								normal:{
									show:true, 
									color: 'blue',
									position: 'insideEndTop',
									formatter: '85% Best Performance: {c}' ,

								}
							}, 
						//	symbol: 'none',
							silent: true,
							data: [ { symbol: null, symbolSize: 0, yAxis: this.bestKpiByPlayer[this.filters.players[0]][kpi] } ]
						}
					});
				}

				var max = this.isShowBestKpi ? _.round(_.ceil(_.max([this.bestKpiByPlayer[this.filters.players[0]][kpi], max_ds, _.sum(_.map(ds2, kpi))/_.size(ds2)]))) : _.round(_.ceil(_.max([max_ds, _.sum(_.map(ds2, kpi))/_.size(ds2)])));

				options.push({
					grid: {
						top: 40,
						left: 50,
						right: 50,
						bottom: 70
					},
					xAxis: {
						type: 'category',
						data: xData.length > 0 ? xData : (this.aggregate != 'day' ? _.sortBy(_.keys(ds), (obj) => parseInt(_.split(obj, ' ')[1], 10)) : this.getDays(ds, kpi)),
						axisTick: {
							show: false
						},
						axisLabel: {
							rotate: 45,
							formatter: function (value) {
								if(value.includes('(M)')) {
									return '{match|' + value + '}'
								}
								else {
									return '{day|' + value + '}'
								}
							},
							rich: {
								match: {
									color: '#000',
									fontWeight: 'bold',
									fontSize: 11,
								},
								day: {
									color: '#999',
									fontSize: 11,
								}
							}
						}
					},
					yAxis: {
						type: 'value',
						splitNumber: 1,
						axisTick: {
							show: false
						},
						max: max
					},
					series: series,
					legend: {
						show: this.level == 'player' ? true : false,
						data: legend,
					}, 
					tooltip: {
						trigger: 'item',
						formatter: function(params) {
							if(params.name.includes('(M)')) {
								var partita = this.matchCalendar[params.name.split(' ')[0]];
								return `${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span><br/><span>${partita}</span>`;
							} else {
								return `${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span>`;
							}
						}.bind(this)
					}
				});

			}.bind(this));

return options;

},

graphOptionByPerson: function() {

	var colors = [
		'#c23531',
		'#2f4554',
		'#61a0a8',
		'#d48265',
		'#91c7ae',
		'#749f83',
		'#ca8622',
		'#bda29a',
		'#6e7074',
		'#546570',
		'#c4ccd3',
		'#c23531',
		'#2f4554',
		'#61a0a8',
		'#d48265',
		'#91c7ae',
		'#749f83',
		'#ca8622',
		'#bda29a',
		'#6e7074',
		'#546570',
		'#c4ccd3'
		];

	var ds2 = this.theFullDataset;
	var optionsPerson = {};

	var index = 0;

	_.forEach(this.theDatasetByPerson, function(ds, person_id) {

		var options = [];

		_.each(this.kpi_selected, function(kpi, u) {

			var series = [];

			if(this.medie.includes('aggregati')) {				
				var serieMedia = {
					data: _.map(ds2, kpi),
					type: 'bar',
					name: this.getLabelKpi(kpi),
					label: {
						show: false
					},
					itemStyle: {
						normal: {
							color: '#ddd',
						}
					},
					barWidth: '60%',
					tooltip: {
						formatter: function (params) {
							return 'Team Average: <strong>' + params.value + '</strong>';
						}
					}
				};
				series.push(serieMedia);
			}

			var serie = {
				data: _.map(ds, kpi),
				type: 'bar',
				name: this.getLabelKpi(kpi),
				color: [
					colors[index]
					],
				label: {
					show: true,
					position: 'top'
				},
				z: 10,
				stack: 'ciao'
			};

			if(this.medie.includes('periodo')) {
				serie.markLine = {
					symbol: 'none',
					silent: true,
					data: [ { symbol: null, symbolSize: 0, yAxis: _.round(_.sum(_.map(ds2, kpi))/_.size(_.filter(_.map(ds2, kpi)))) } ]
				}

			}
			if(this.medie.includes('aggregati')) {
				serie.barWidth = '30%';
				serie.barGap = '-75%';
			}
			series.push(serie);

			if(this.medie.includes('media')) {
				series.push({
					data: _.map(ds, function() { return null; }), 
					type: 'bar', 
					stack: 'ciao', 
					name: this.getLabelKpi(kpi), 
					markLine: {
						symbol: 'none',
						silent: true,
						data: [ { symbol: null, symbolSize: 0, yAxis: _.round(_.sum(_.map(ds, kpi))/_.size(_.filter(_.map(ds, kpi)))) } ]
					}
				});
			}

			var max = this.getMaxValueKpi(kpi);

			options.push({
				grid: {
					top: 25,
					left: 50,
					right: 50,
					bottom: 70
				},
				xAxis: {
					type: 'category',
					data: this.aggregate != 'day' ? _.sortBy(_.keys(ds), (obj) => parseInt(_.split(obj, ' ')[1], 10)) : _.sortBy(_.keys(ds)),
					axisTick: {
						show: false
					},
					axisLabel: {
						rotate: 45,
						formatter: function (value) {
							if(value.includes('(M)')) {
								return '{match|' + value + '}'
							}
							else {
								return '{day|' + value + '}'
							}
						},
						rich: {
							match: {
								color: '#000',
								fontWeight: 'bold',
								fontSize: 11,
							},
							day: {
								color: '#999',
								fontSize: 11,
							}
						}
					}
				},
				yAxis: {
					type: 'value',
					splitNumber: 1,
					axisTick: {
						show: false
					},
					max: max
				},
				series: series,
				legend: {
					show: false
				}, 
				tooltip: {
					trigger: 'item',
					formatter: function(params) {
						if(params.name.includes('(M)')) {
							var partita = this.matchCalendar[params.name.split(' ')[0]];
							return `${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span><br/><span>${partita}</span>`;
						} else {
							return `${params.seriesName}<br>${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value}</b></span>`;
						}
					}.bind(this)
				}
			});

		}.bind(this));

		optionsPerson[person_id] = options;

		index++;

	}.bind(this));

	return optionsPerson;

}
},

methods: {
	getBestKpiPlayer(player_id) {
		this.bestKpiLoading = true;
		this.$http.get(this.$store.state.apiEndPoint + '/tools/best/kpi', {params: {persona_id: player_id, categoria_id: this.categoriaId, from: this.bestKpiFrom, to: this.bestKpiTo }}).then(function(res) {
			this.bestKpiByPlayer[player_id] = res.data;
			this.random = Math.random();
			this.bestKpiLoading = false;
		});
	},
	getDays(dataset, kpi) {
		var days_ds = _.keys(dataset);
		if(this.daysOffShow) {
			var key = Object.keys(this.daysOffKpi).find(key => this.daysOffKpi[key].includes(kpi));
			var days_off = [];
			if(this.level == 'player') {
				_.forEach(this.daysOffByPerson, function (items) {
					days_off = days_off.concat(items[key]);	
				});
			} else {
				days_off = this.daysOff[key];
			}
			days_ds = _.uniq(days_ds.concat(days_off));	
		} 
		return _.sortBy(days_ds);
	},
	getSerie(dataset, kpi) {
		if(this.daysOffShow) {
			var data = [];
			var keys = Object.keys(dataset);
			var days = this.getDays(dataset, kpi);
			for(var i in days) {
				var day = days[i];
				if(keys.includes(day)) {
					data.push(dataset[day][kpi]);
				} else {
					data.push(0);
				}
			}
			return data;
		} 
		return _.map(dataset, kpi);
	},
	getSerieByPerson(dataset, kpi, person_id) {
		if(this.daysOffShow) {
			var data = [];
			var keys = Object.keys(dataset);
			var days = this.getDays(dataset, kpi, person_id);
			for(var i in days) {
				var day = days[i];
				if(keys.includes(day)) {
					data.push(dataset[day][kpi]);
				} else {
					data.push(0);
				}
			}
			return data;
		} 
		return _.map(dataset, kpi);
	},
	hasDetailPlayer(kpi) {
		return this.ux.detailPlayer.includes(kpi);
	},
	showDetailPlayer(kpi) {
		if(!this.ux.detailPlayer.includes(kpi)) {
			this.ux.detailPlayer.push(kpi);
		}
	},
	hideDetailPlayer(kpi) {
		var index = this.ux.detailPlayer.indexOf(kpi);
		if(index > -1) {
			this.ux.detailPlayer.splice(index, 1);
		}
	},
	getMaxValueKpi: function (kpi) {
		var array_kpi = [];
		var ds2 = this.theFullDataset;
		array_kpi.push(_.sum(_.map(ds2, kpi))/_.size(ds2));
		_.forEach(this.theDatasetByPerson, function(ds) {
			var value = _.max(_.map(ds, kpi));
			array_kpi.push(value);
		});	
		return _.round(_.ceil(_.max(array_kpi)));
	},
	meanOf: function(key) {

		var ds = _.map(this.theTables, function(a) {
			return toolsHelper.isValidNumber(a.table[key]) ? a.table[key] : 0;
		});

		if(ds.length)
			return _.round(_.sum(ds)/ds.length, 0);
		return ''
	},
	setFocus: function(event) {
		var x = _.keys(this.theDataset)[event.dataIndex];
		if(x.includes('Week')) {
			this.ux.weekFocus = x;
		} else {
			return false;
		}
	},
	getLabelKpi: function(kpi) {
		return this.kpi_options.find(item => item.value == kpi).text;
	},
	getPerson(person_id) {
		return this.results.raw_persone.find(item => item.id == person_id);
	},
},

mounted: function() {
	this.loading = false;
},
created() {
	this.bestKpiFrom = this.user.seasons[0].start_at.split(' ')[0];
	this.bestKpiTo = this.user.seasons[0].end_at.split(' ')[0];
}
}

</script>