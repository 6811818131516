<template>

	<div>

		<headapp></headapp>

		<b-card no-body>
			<b-tabs card>
				<b-tab title="Esplora">

					<div style="background-color: white" class="breadcrumbWrap">
						<div style="background-color: rgba(0,0,0,.06); border-radius: 3px; width: auto" class="px-4 py-2 mb-1">
							<b-row align-v="center">
								<b-col cols="6">
									<b-breadcrumb style="background-color: transparent" class="m-0 p-0" :items="breadcrumb"></b-breadcrumb>
								</b-col>
								<b-col class="text-right">
									<b-row align-v="center">
										<b-col cols="6" class="text-center">
											<b-form-radio-group v-model="ux.genere" size="sm">
												<b-form-radio value="male">Maschile</b-form-radio>
												<b-form-radio value="female">Femminile</b-form-radio>
											</b-form-radio-group>
										</b-col>
										<b-col cols="6">
											<b-form-input size="sm" v-model="ux.search" placeholder="Quick search"></b-form-input>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</div>
					</div>
					<b-card title-tag="div">
						<b-overlay :show="ux.loading">
							<b-row>
								<b-col sm="1" v-for="squadra in filtered_squadre" style="padding: 0.2rem">
									<router-link :to="{ name: 'scout_nazionali_squadra', params: { continente_id: $route.params.continente_id, paese_id: $route.params.paese_id, squadra_id: squadra.id } }">
										<b-card class="small text-center" style="height:100%">
											<b-img :src="squadra.logo_url" fluid :alt="squadra.officialName" :width="120"/>
											<br/>
											<span>{{squadra.officialName}}</span>
										</b-card>
									</router-link>
								</b-col>
							</b-row>
						</b-overlay>
					</b-card>
				</b-tab>
				<b-tab title="Cerca">
					<search />
				</b-tab>
			</b-tabs>
		</b-card>
		<footerapp></footerapp>

	</div>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			continente: null,
			squadre: null,
			paese: null,
			ux: {
				search: '',
				loading: true,
				genere: 'male'
			},
		}
	},
	components: {
		flag: require('@/components/layout/flag.vue').default,
		search: require('@/components/layout/scout/search.vue').default,
	},
	watch: {
		'$route.params.paese_id': {
			immediate: true,
			handler: function() {
				this.fetchNazionali();
				this.fetchContinente();
				this.fetchPaese();
			}
		}
	},
	methods: {
		fetchContinente: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/paese/' + this.$route.params.continente_id).then(function(data) {
				this.continente = data.data;
			});
		},
		fetchPaese: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/paese/' + this.$route.params.paese_id).then(function(data) {
				this.paese = data.data;
			});
		},
		fetchNazionali: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/nazionali/' + this.$route.params.paese_id).then(function(data) {
				this.ux.loading = false;
				this.squadre = data.data;
			});
		},
	},
	computed: {
		breadcrumb: function() {

			var b = [];

			b.push({
				text: 'Continenti',
				to: {
					name: 'scout_continenti'
				}
			});

			b.push({
				text: this.continente ? this.continente.paese : '',
				to: {
					name: 'scout_paesi',
					params: {
						continente_id: this.$route.params.continente_id
					}
				}
			});

			b.push({
				text: this.paese ? this.paese.paese : '',
				to: {
					name: 'scout_competizioni',
					params: {
						continente_id: this.$route.params.continente_id,
						paese_id: this.$route.params.paese_id
					}
				}
			});

			b.push({
				text: 'Nazionali',
				to: {
					name: 'scout_nazionali',
					params: {
						continente_id: this.$route.params.continente_id,
						paese_id: this.$route.params.paese_id
					}
				}
			});

			return b;

		},
		filtered_squadre: function () {

			return _.filter(this.squadre, function(squadra) {

				if(this.ux.search != "")
					if(!squadra.officialName.toLowerCase.includes(this.ux.search.toLowerCase()))
						return false;

					return this.ux.genere == squadra.gender;

				}.bind(this));

		},
	}
}

</script>