<template>
	<div>
		<b-card no-body :class="'p-2 mb-1' + (hasValBreve(player.persona_id) ? ' border-dark' : '')" v-for="(player, index) in theFormazione" :bg-variant="index % 2 == 0 ? 'light' : 'default'">
			<b-row>
				<b-col cols="1">
					<strong>{{ player.shirtNumber }}</strong>
				</b-col>
				<b-col cols="1">
					<em>{{ player.role.code3 }}</em>
				</b-col>
				<b-col>
					<!--router-link :to="'/next/' + area + '/view/giocatore/' + player.persona_id" target="_blank" v-if="player.persona_id && !hideValutazioni">
						{{ player.shortName }}
					</router-link>
					<div v-else> {{ player.shortName }}</div-->
					<div> {{ player.shortName }}</div>
				</b-col>
				<b-col cols="3" v-if="!hideValutazioni">
					<valutazione-button :player="{ name: player.firstName, surname: player.lastName, role: player.role.code2.toLowerCase(), avatar: player.imageDataURL, team: match.squadra }" :match="match"></valutazione-button>
					<!--div class="row no-gutters text-center">
						<div class="col mr-1" title="A"><b-badge variant="bg-1" v-if="player.persona_id && isValutato(player.persona_id, 1)" style="border:1px solid #006600;">&nbsp;</b-badge><b-badge v-else variant="bg-1" style="background-color:rgba(0,179,134,0.1)!important;border:1px solid #006600;">&nbsp;</b-badge></div>
						<div class="col mr-1" title="B+"><b-badge variant="bg-2" v-if="player.persona_id && isValutato(player.persona_id, 2)" style="border:1px solid #006600;">&nbsp;</b-badge><b-badge v-else variant="bg-2" style="background-color:rgba(123,222,60,0.1)!important;border:1px solid #00BB00;">&nbsp;</b-badge></div>
						<div class="col mr-1" title="B"><b-badge variant="bg-3" v-if="player.persona_id && isValutato(player.persona_id, 3)" style="border:1px solid #FF9900;">&nbsp;</b-badge><b-badge v-else variant="bg-3" style="background-color:rgba(255,243,0,0.1)!important;border:1px solid #FF9900;">&nbsp;</b-badge></div>
						<div class="col mr-1" title="C"><b-badge variant="bg-5" v-if="player.persona_id && isValutato(player.persona_id, 5)" style="border:1px solid #990000;">&nbsp;</b-badge><b-badge v-else variant="bg-5" style="background-color:rgba(255,0,89,0.1)!important;border:1px solid #990000;">&nbsp;</b-badge></div>
					</div-->
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			
		}
	},
	components: {
		ValutazioneButton: require('@/components/layout/Valutazioni/Button.vue').default,
	},
	props: [ 'formazione', 'valutazioni', 'hideValutazioni', 'match' ],
	methods: {
		hasValBreve: function(persona_id) {
			return this.valutazioni.find(item => item.persona_id == persona_id && item.breve);
		},
		isValutato: function(persona_id, grad) {
			return this.valutazioni.find(item => item.persona_id == persona_id && item.id_gradimento == grad);
		}
	},
	computed: {
		area: function() {
			return this.$route.meta.area;
		},
		theFormazione: function() {
			return this.formazione.sort(function(a, b) { 
				var roles_ws = ['GKP', 'DEF', 'MID', 'FWD', 'N/D'];
				var role_a = a.role ? a.role.code3 : 'N/D';
				var role_b = b.role ? b.role.code3 : 'N/D';
				return roles_ws.indexOf(role_a) - roles_ws.indexOf(role_b);
			});
		}
	}

}

</script>