<template>
	<div>
		<b-card>
			<template slot="header">Impact on the game</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Resolving problems created by the opponent</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_2, kpis.val_3 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CREATING PROBLEMS TO THE OPPONENT</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_2"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>INTERPRETATION OF GAME SITUATIONS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_3"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>


			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Physical Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>PACE/ TURN OF PACE / ACCELERATION</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_4"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>ACTIVATION / REACTIONS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_5"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Power</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_6"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO IMPOSE HIMSELF IN CONTACT DUELS</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_7"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>CAPACITY TO REPEAT HIGH INTENSITY RUNS / GENEROSITY</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_8"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>IN THE AIR</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_9"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>COOORDINATION / BALANCE / AGILITY</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_10"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Technical Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Receiving skills / Quality of the 1st touch</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_11"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Ball manipulation</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_12"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Passing range (short-medium-long)</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_13"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Attacking end product</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_14"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>DEFENSIVE CLEARANCES</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_15"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>USE OF WEAKER FOOT</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_16"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>SET PIECES DELIVERIES</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_17"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Psychological Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Interactions with the different actors of the game</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_18"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Body language</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_19"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Reaction after key moments</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_20"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Presence / Aura</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_21"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
	</div>
</template>

<script>

export default {
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
	},
	methods: {
		mean: function(arr) {
			return _.mean(_.filter(arr));
		}
	},
	data: function() {
		return {
			kpis: {
				val_1: null,
				val_2: null,
				val_3: null,
				val_4: null,
				val_5: null,
				val_6: null,
				val_7: null,
				val_8: null,
				val_9: null,
				val_10: null,
				val_11: null,
				val_12: null,
				val_13: null,
				val_14: null,
				val_15: null,
				val_16: null,
				val_17: null,
				val_18: null,
				val_19: null,
				val_20: null,
				val_21: null
			}
		}
	}
}

</script>