<template>
	<div>
		<b-input v-b-popover.hover="{ html: true, content: legendHtml }" title="Legend" @change="trigChange" :disabled="disabled" :style="style" type="number" size="sm" min="0" max="5" step="1" v-model="val" />
	</div>
</template>

<script>

export default {
	props: [ 'disabled', 'value' ],
	methods: {
		trigChange: function() {
			this.$emit('input', parseFloat(this.val));
		}
	},
	data: function() {
		return {
			val: null
		}
	},
	watch: {
		value: function(u) {
			this.val = u;
		}
	},
	created: function() {
		this.val = _.cloneDeep(this.value);
	},
	computed: {
		legendHtml: function() {
			var html = [];

			html.push('<div><span style="background-color: #674ea7; display: inline-block; width: 10px; height: 10px; border-radius: 100px"></span> 0-1 • Deuxième division</div>');
			html.push('<div><span style="background-color: #ea4336; display: inline-block; width: 10px; height: 10px; border-radius: 100px"></span> 1-2 • Bas de tableau 1ère division</div>');
			html.push('<div><span style="background-color: #ff6d01; display: inline-block; width: 10px; height: 10px; border-radius: 100px"></span> 2-3 • Milieu de tableau 1ère division</div>');
			html.push('<div><span style="background-color: #34a853; display: inline-block; width: 10px; height: 10px; border-radius: 100px"></span> 3-4 • Top 5 1ère division</div>');
			html.push('<div><span style="background-color: #46bdc6; display: inline-block; width: 10px; height: 10px; border-radius: 100px"></span> 4-5 • Élite</div>');

			return html.join("\n");

		},
		style: function() {

			var style = [];

			if(this.val > 0 && this.val <= 1) style.push('background-color: #674ea7; color: white;');
			if(this.val > 1 && this.val <= 2) style.push('background-color: #ea4336; color: white;');
			if(this.val > 2 && this.val <= 3) style.push('background-color: #ff6d01; color: white;');
			if(this.val > 3 && this.val <= 4) style.push('background-color: #34a853');
			if(this.val > 4 && this.val <= 5) style.push('background-color: #46bdc6');

			return style.join('; ');

		}
	}
}

</script>