<template>
	<div>
		<b-card>
			<template slot="header">Impact on the game</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Resolving problems created by the opponent</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([kpis.val_2, kpis.val_3])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to press and harass</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_2"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Reaction at ball loss</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_3"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Creating problems to the opponent</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_5, kpis.val_6, kpis.val_7, kpis.val_8, kpis.val_9, kpis.val_10 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Attacking the space in behind</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_5"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Back to goal play</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_6"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Combination play</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_7"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to eliminate in 1vs1 situations (outside / inside)</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_8"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to be creative</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_9"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Clinical in front of goal</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_10"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-th>Interpretation of game situations</b-th>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag :value="mean([ kpis.val_12, kpis.val_13, kpis.val_14, kpis.val_15 ])" :disabled="true"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Scanning / Awareness</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_12"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Composure under pressure</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_13"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Decision making</b-td>
							<b-td><Smart-Tag v-model="kpis.val_14"></Smart-Tag></b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Movements and capacity to lose the mark</b-td>
							<b-td><Smart-Tag v-model="kpis.val_15"></Smart-Tag></b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Physical Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Pace/Turn of pace/Acceleration</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_16"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Activation / Reactions</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_17"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Power</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_18"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to impose himself in contact duels</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_19"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Capacity to repeat high intensity runs / generosity</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_20"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>In the air</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_21"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Cooordination/Balance/Agility</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_22"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Technical Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Receiving skills / Quality of the 1st touch</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_23"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Ball manipulation</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_24"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Passing range (short-medium-long)</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_25"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Attacking end product</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_26"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Defensive clearances</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_27"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Use of weaker foot</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_28"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Set pieces deliveries</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_29"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
		<b-card class="mt-2">
			<template slot="header">Psychological Profile</template>
			<div>
				
				<b-table-simple small striped>
					<b-thead>
						<b-tr>
							<b-th width="55%"></b-th>
							<b-th>Comment</b-th>
							<b-th width="10%">Val</b-th>
							<b-th width="7%"></b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr>
							<b-td>Interactions with the different actors of the game</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_30"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Body language</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_31"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Reaction after key moments</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_32"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
						<b-tr>
							<b-td>Presence / Aura</b-td>
							<b-td><b-input size="sm" /></b-td>
							<b-td><Smart-Tag v-model="kpis.val_33"></Smart-Tag></b-td>
							<b-td class="text-center">
								<b-button-group class="mx-1">
									<b-button size="xs">VID</b-button>
								</b-button-group>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>

			</div>
		</b-card>
	</div>
</template>

<script>

export default {
	data: function() {
		return {
			kpis: {
				val_1: null,
				val_2: null,
				val_3: null,
				val_4: null,
				val_5: null,
				val_6: null,
				val_7: null,
				val_8: null,
				val_9: null,
				val_10: null,
				val_11: null,
				val_12: null,
				val_13: null,
				val_14: null,
				val_15: null,
				val_16: null,
				val_17: null,
				val_18: null,
				val_19: null,
				val_20: null,
				val_21: null,
				val_22: null,
				val_23: null,
				val_24: null,
				val_25: null,
				val_26: null,
				val_27: null,
				val_28: null,
				val_29: null,
				val_30: null,
				val_31: null,
				val_32: null,
				val_33: null
			}
		}
	},
	methods: {
		mean: function(arr) {
			return _.mean(_.filter(arr));
		}
	},
	components: {
		SmartTag: require('@/components/layout/Valutazioni/Tag.vue').default
	}
}

</script>