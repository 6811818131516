<template>

	<div>

		<headapp></headapp>

		<b-card no-body>
			<b-tabs card>
				<b-tab title="Esplora">

					<div style="background-color: white" class="breadcrumbWrap">
						<div style="background-color: rgba(0,0,0,.06); border-radius: 3px; width: auto" class="px-4 py-2 mb-1">
							<b-row align-v="center">
								<b-col cols="9">
									<b-breadcrumb style="background-color: transparent" class="m-0 p-0" :items="breadcrumb"></b-breadcrumb>
								</b-col>
								<b-col class="text-right">
									<b-form-input size="sm" v-model="ux.search" placeholder="Quick search"></b-form-input>
								</b-col>
							</b-row>
						</div>

					</div>

					<b-card title-tag="div">
						<b-overlay :show="ux.loading">
							<b-row>
								<b-col sm="2" style="padding: 0.2rem" v-if="$route.params.continente_id != $route.params.paese_id">
									<b-card class="small text-center" style="height:100%">
										<router-link :to="{ name: 'scout_nazionali', params: { continente_id: $route.params.continente_id, paese_id: $route.params.paese_id } }">
											<flag v-if="paese" :country="paese.sigla" :size="64"></flag><br/>
											<strong>Nazionali</strong>
										</router-link>
									</b-card>
								</b-col>
								<b-col sm="2" v-for="competizione in filtered_competizioni" style="padding: 0.2rem">
									<b-card class="small text-center" style="height:100%">
										<router-link :to="{ name: 'scout_competizione', params: { continente_id: $route.params.continente_id, paese_id: $route.params.paese_id, competizione_id: competizione.id } }">
											<strong>{{competizione.nome}}</strong><br/>
											<span class="font-size-xs color-rosso">{{$i18n.t(competizione.formato)}}</span><br/>
										</router-link>
										<div v-if="competizione.manual_insert" class="mt-1"><b-button size="sm" title="Modifica competizione" @click.prevent="showEditCompetizione(competizione)"><i class="fa fa-pencil"></i></b-button></div>
									</b-card>
								</b-col>
								<b-col sm="2" v-if="auth.isUserEnable('scout_comp_mng')" style="padding: 0.2rem">
									<b-card class="small text-center" style="cursor: pointer;height:100%;border: 5px dashed #e5e5e5;" @click="showAddCompetizione">
										<div class="text-center">
											<p class="mb-0"><i class="fa fa-plus-circle text-muted fa-5x"></i></p>
											<strong class="text-uppercase text-muted">Nuova competizione</strong>
										</div>
									</b-card>
								</b-col>
							</b-row>
					</b-overlay>
					<b-modal id="compModal" ref="compModal" :title="ux.title" ok-only ok-title="Chiudi">
						<b-form-group label="Nome">
							<b-form-input type="text" v-model="form.nome"></b-form-input>
						</b-form-group>
						<b-form-group label="Tipo">
							<b-form-select v-model="form.formato">
								<option value="">-- Tipo --</option>
								<option v-for="item in comp_type_opt" :value="item.value">{{ item.label }}</option>
							</b-form-select>
						</b-form-group>
						<b-form-group label="Livello">
							<b-form-select v-model="form.livello">
								<option value="0">0</option>
								<option v-for="i in 5" :value="i">{{ i }}</option>
							</b-form-select>
						</b-form-group>
						<b-button type="button" @click="salvaCompetizione" variant="primary" class="mt-3">Salva</b-button>
					</b-modal>
				</b-card>

			</b-tab>
			<b-tab title="Cerca">
				<search />
			</b-tab>
		</b-tabs>

	</b-card>

	<footerapp></footerapp>

</div>

</template>

<script>

import pageBase from '@/mixins/pageBase.js'

export default {
	mixins: [ pageBase ],
	data: function() {
		return {
			continente: null,
			paese: null,
			competizioni: null,
			ux: {
				search: '',
				loading: true,
				title: '',
			},
			comp_type_opt: [
				{ value: 'Domestic cup' , label: 'Coppa nazionale'},
				{ value: 'Domestic league' , label: 'Lega nazionale'},
				{ value: 'Domestic super cup' , label: 'Super coppa nazionale'},
				{ value: 'International cup' , label: 'Coppa internazionale'},
				{ value: 'International super cup' , label: 'Super coppa internazionale'},
				],
			form: {
				id: null,
				nome: null,
				formato: null,
				livello: 0,
				id_paese: null,
				tipo: 'club',
			},
		}
	},
	components: {
		flag: require('@/components/layout/flag.vue').default,
		search: require('@/components/layout/scout/search.vue').default,
	},
	watch: {
		'$route.params.paese_id': {
			immediate: true,
			handler: function() {
				this.fetchContinente();
				this.fetchPaese();
				this.fetch();
			}
		}
	},
	methods: {
		fetchContinente: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/paese/' + this.$route.params.continente_id).then(function(data) {
				this.continente = data.data;
			});
		},
		fetchPaese: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/club/paese/' + this.$route.params.paese_id).then(function(data) {
				this.paese = data.data;
			});
		},
		fetch: function() {
			this.ux.loading = true;
			this.$http.get(this.$store.state.apiEndPoint + '/club/competizioni/' + this.$route.params.paese_id).then(function(data) {
				this.competizioni = data.data;
				this.ux.loading = false;
			});
		},
		showAddCompetizione() {
			this.ux.title = 'Nuova competizione';
			this.form.id = null;
			this.form.nome = null;
			this.form.formato = null;
			this.form.livello = 0;
			this.form.id_paese = this.$route.params.paese_id;
			this.$refs.compModal.show();
		},
		showEditCompetizione(competizione) {
			this.ux.title = 'Modifica competizione';
			this.form.id = competizione.id;
			this.form.nome = competizione.nome;
			this.form.formato = competizione.formato;
			this.form.livello = competizione.livello;
			this.form.id_paese = competizione.id_paese;
			this.$refs.compModal.show();
		},
		salvaCompetizione() {
			if(!this.form || this.form.nome == '') {
				alert("Devi inserire un nome per la competizione!");
				return;
			}
			if(!this.form.formato || this.form.formato == '') {
				alert("Devi selezionare la tipologia di competizione!");
				return;
			}
			this.$http.post(this.$store.state.apiEndPoint + '/nazionale/salva/competizione', this.form).then(function(data) {
				this.alertPush(data.data.message, 1);
				this.fetch();
				this.$refs.compModal.hide();
			}).catch(function (error) {
				for (var index in error.data) {
					this.alertPush(error.data[index], 0);
				}
			});
		},
	},
	computed: {
		breadcrumb: function() {

			var b = [];

			b.push({
				text: 'Continenti',
				to: {
					name: 'scout_continenti'
				}
			});

			b.push({
				text: this.continente ? this.continente.paese : '',
				to: {
					name: 'scout_paesi',
					params: {
						continente_id: this.$route.params.continente_id
					}
				}
			});

			b.push({
				text: this.paese ? ( this.$route.params.paese_id == this.$route.params.continente_id ? 'Competizioni internazionali' : this.paese.paese) : '',
				to: {
					name: 'scout_competizioni',
					params: {
						continente_id: this.$route.params.continente_id,
						paese_id: this.$route.params.paese_id
					}
				}
			});

			return b;

		},
		filtered_competizioni: function () {
			if (this.ux.search != "") {
				return this.competizioni.filter((competizione) =>
					competizione.nome
					.toLowerCase()
					.includes(this.ux.search.toLowerCase())
					);
			}
			return this.competizioni;
		},
	}
}

</script>